import CTABanner from "components/common/footer-banners/CTABanner";
import HeroLayout1 from "components/common/hero/HeroLayout1";
import InfoSlider from "components/common/InfoSlider";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

import Founders from "./Founders";
import Investors from "./Investors";
import TeamGrid from "./TeamGrid";

export default function AboutPage() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "About Rhombus ",
        title: "Our Mission ",
        description:
          "At Rhombus, relationships are at the heart of what we do. No matter how big or small your company is, our mission is to build a true partnership and provide simple smart, and powerful solutions that fit your unique security needs.",
        image: data.headerImage,
      },
      infoSlides: [
        {
          tabTitle: "Customer-Centric",
          title: "Customers Come First",
          description:
            "Relationships are at the heart of what we do, and we always put our customers and partners first. Whether it’s listening to user feedback, creating products that are enjoyable to use, or taking the time to build strong and friendly relationships, our goal is to create positive experiences.",
          image: data.infoSlide1,
        },
        {
          tabTitle: "Bold Ingenuity",
          title: "Keep Innovating",
          description:
            "Rhombus is committed to a spirit of innovation. We push ourselves to think bigger, challenge the status quo, and create better, more innovative ways to serve our partners and customers.",
          image: data.infoSlide2,
        },
        {
          tabTitle: "Trustworthy Partner",
          title: "Integrity Above All ",
          description:
            "Rhombus is committed to being your trusted security partner. We value sound business ethics, integrity, and relationships. We believe honest communication is key to success, and we conduct business the way it should be—with high ethical standards, transparency, and to always do what’s right.",
          image: data.infoSlide3,
        },
        {
          tabTitle: "Simplified Security ",
          title: "Make It Easy ",
          description:
            "We believe that physical security doesn’t have to be stressful. Too many enterprise security systems are gated behind high complexity and cost. From the beginning, our founders set out to make the world safer with a powerful, enterprise-grade platform that was intuitive enough for anyone to use.",
          image: data.infoSlide4,
        },
      ],
    };
    return (
      <>
        <HeroLayout1 data={pageData.headerSection} />
        <InfoSlider data={pageData.infoSlides} title="Our Values" />
        <Founders />
        <Investors />
        <TeamGrid />
        <CTABanner />
      </>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/about/img/rhombus-building-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/about/img/customer-centric-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/about/img/ingenuity-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/about/img/integrity-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/about/img/simplified-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
