import AboutPage from "components/about/AboutPage";
import React from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../components/page/GlobalLayout";

export default function About() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>About Rhombus – Make the World Safer</title>
        <meta
          name="description"
          content="Learn what Rhombus is all about and our mission to make the world safer with simple, smart, and powerful physical security solutions."
        />
      </Helmet>
      <AboutPage />
    </GlobalLayout>
  );
}
